import React, {useEffect} from 'react';
import WinterStorageForm from './Components/WinterStorageForm'
import './App.css';

function App() {
  function iframeResize() {
    let height = document.getElementById('app').offsetHeight;
    window.parent.postMessage("resize::"+height,"*");
  }

  useEffect(() => {
    const interval= setInterval(iframeResize, 1000);
    return () => clearInterval(interval);
  },[])
  
  return ( 
    <div id="app" className="App">
      <WinterStorageForm/>
    </div>
  );
}

export default App;

import React, {useEffect, useState, useRef} from 'react'
import Calendar from './Calendar'
import FormElement from './Form-Element'
import './WinterStorageForm.css'

function WinterStorageForm() {
  const [fetchErrorMessage, setFetchErrorMessage] = useState('') // if database wasnt reached or other error
  const [form, setForm]                           = useState({engine_amount: 1, phone: "+358", engine_internal: false}) // pre-set values
  const [formError, setFormError]                 = useState({}) // same object as form, except contains the error strings
  const [errorMessage, setErrorMessage]           = useState("") // popup formErrors to client
  const [fromDB, setFromDB]                       = useState({}) // retrieved data from DB
  const [formSuccess, setFormSuccess]             = useState(false) //
  const [toggle, setToggle]                       = useState(false) // info square toggle

  const loading = useRef(true)
  const langRef = useRef('fi')
  let lang

  async function fetchDatabase() {
    let direction
    if (window.location.pathname==='/sv') {langRef.current = 'sv'} else {langRef.current = 'fi'}
    lang= langRef.current;
    if (langRef.current==="fi") {direction= "/suunta"} else {direction= "/håll"}
    try {
      let res= await fetch('https://app.venekeskus.com/api/customer/formdata?lang='+ langRef.current, {
        method: 'GET',
        mode: 'cors',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        } 
      })
      let data = await res.json();
      if (res.status===200) {
        let language_settings= {}
        data.language_settings.map(a => {
          return language_settings[a.name]= {text: a[lang], active: a.active}
        })
        data.language_settings= language_settings
        data.pickup_locations.forEach(pickup_location =>  {
          if (pickup_location.show_price===1) {
            return pickup_location[lang] += ` (${parseFloat(pickup_location.price)}€${pickup_location.price>0.00?direction:""})`
          }
        })
        data.service_radiobuttons.forEach(service_radiobutton =>  {
          if (service_radiobutton.show_price===1) {
            return service_radiobutton[lang] += ` (${parseFloat(service_radiobutton.price_per_hour)}€/h)`
          }
          data.service_radiobuttons.sort((a, b) => { return a.order- b.order})
        })
        loading.current= false;
        //console.log(data)
        setFromDB(data)
      } else {
        setFetchErrorMessage(lang==='fi'?
          "Valitettavasti palvelin ei vastaa, kokeile uudestaan hetken kuluttua tai ota yhteyttä myyjään!":
            "Tyvärr svarar inte servern, prova pånytt om en stund eller tag kontakt med en försäljare!")
      }
    } catch (e) {
      setFetchErrorMessage(lang==='fi'?
        "Valitettavasti palvelin ei vastaa, kokeile uudestaan hetken kuluttua tai ota yhteyttä myyjään!":
          "Tyvärr svarar inte servern, prova pånytt om en stund eller tag kontakt med en försäljare!")
    }
  }


  useEffect(()=> {
    fetchDatabase();
  }, [])
  
  lang= langRef.current;
  
  const PopUpError = () => {
    return (
      <div className="Form-Error-Container">
        <p>{errorMessage}</p>
      </div>
    )
  }

  const OnChange = (e) => {
    setForm(prevState => ({...prevState, [e.target.name]: e.target.value}))
    setFormError(prevState => ({...prevState, [e.target.name]: false}))
  }

  const OnChangeName = (e) => {
    const nameRegex = /^[a-zA-ZàáâäãåąčćęèéêëėįìíîïłńòóôöõøùúûüųūÿýżźñçčšžÀÁÂÄÃÅĄĆČĖĘÈÉÊËÌÍÎÏĮŁŃÒÓÔÖÕØÙÚÛÜŲŪŸÝŻŹÑßÇŒÆČŠŽ∂ð ,.'-]+$/u;
    if (nameRegex.test(e.target.value)||!e.target.value)
      setForm(prevState => ({...prevState, [e.target.name]: e.target.value}))
    setFormError(prevState => ({...prevState, [e.target.name]: false}))
  }

  const OnChangePhone = (e) => {
    const phoneRegex = /^[0-9+]+$/
    if (phoneRegex.test(e.target.value)||!e.target.value)
      setForm(prevState => ({...prevState, [e.target.name]: e.target.value}))
    setFormError(prevState => ({...prevState, [e.target.name]: false}))
  }

  const OnChangeMeters = (e) => {
    const realRegex = /^\d[\d,]*(\.\d+)?$/
    if (realRegex.test(e.target.value)||!e.target.value)
      setForm(prevState => ({...prevState, [e.target.name]: e.target.value}))
    setFormError(prevState => ({...prevState, [e.target.name]: false}))
  }

  const CheckFormErrors = () =>{
    let error= false;
    const emailRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    const realRegex = /^\d[\d,]*(\.\d+)?$/
    if (form.name&&form.name!=='') {
      if (form.name.length<5) {
        setFormError(prevState => ({...prevState, name: lang==='fi'?"Nimikenttä liian lyhyt":"Namnfältet för kort"}));
        error= true;
      } else 
      if (!form.name.split(' ')[1]) {
        setFormError(prevState => ({...prevState, name: lang==='fi'?"Täytä etu- ja sukunimi":"Fyll i för- och efternamn"}))
        error= true;
      }
    }
    if ((form.phone&&form.phone!=='')&&form.phone!=="+358") {
      if (form.phone.length<5) {
        setFormError(prevState => ({...prevState, phone: lang==='fi'?"Puhelinnumero liian lyhyt":"Telefonnumret är för kort"}))
        error= true;
      }
    }
    if (form.email&&form.email!=='') {
      if (!emailRegex.test(form.email)) {
        setFormError(prevState => ({...prevState, email: lang==='fi'?"Sähköpostiosoite viallinen":"E-post adressen är felaktig"}))
        error= true;
      }
    }
    if (form.boat&&form.boat!=='') {
      if (form.boat.length<2) {
        setFormError(prevState => ({...prevState, boat: lang==='fi'?"Viallinen kenttä":"Fältet har ett fel"}))
        error= true;
      }
    }
    if (form.boat_length&&form.boat_length!=='') {
      if (!realRegex.test(form.boat_length)) {
        setFormError(prevState => ({...prevState, boat_length: lang==='fi'?"Viallinen, esimerkki 2,2":"Fel, exempel 2,2"}))
        error= true;
      }
    }
    if (form.boat_width&&form.boat_width!=='') {
      if (!realRegex.test(form.boat_width)) {
        setFormError(prevState => ({...prevState, boat_width: lang==='fi'?"Viallinen, esimerkki 2,2":"Fel, exempel 2,2"}))
        error= true;
      }
    }
    if (form?.pickup_location==='other'&&form?.pickup_other===undefined&&form?.pickup_and_launch==='yes') {
      setFormError(prevState => ({...prevState, pickup_other: lang==='fi'?"Täytä paikkakunta":"Fyll i plats"}))
      error= true;
    }
    return error;
  }

  const CheckFormNotNulls = () => {
    let error= false;
    const emailRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    if(!form.name||form.name==="") {setFormError(prevState => ({...prevState, name: lang==='fi'?"Nimi liian lyhyt":"Namnet är för kort"})); error=true}
    if(!form.phone||form.phone.length<5) {setFormError(prevState => ({...prevState, phone: lang==='fi'?"Puhelinnumero liian lyhyt":"Telefonnumret är för kort"})); error=true}
    if(!form.email||!emailRegex.test(form.email)) {setFormError(prevState => ({...prevState, email: lang==='fi'?"Sähköpostiosoite pakollinen":"E-post addressen felaktig"})); error=true}
    if(!form.boat||form.boat==="") {setFormError(prevState => ({...prevState, boat: lang==='fi'?"Veneen merkki ja malli pakollinen":"Båtens märke och modell krävs"})); error=true}
    if(!form.boat_length||form.boat_length==="") {setFormError(prevState => ({...prevState, boat_length: lang==='fi'?"Veneen pituus pakollinen":"Båtens längd krävs"})); error=true}
    if(!form.boat_width||form.boat_width==="") {setFormError(prevState => ({...prevState, boat_width: lang==='fi'?"Veneen leveys pakollinen":"Båtens bredd krävs"})); error=true}
    if(!form.engine||form.engine==="") {setFormError(prevState => ({...prevState, engine: lang==='fi'?"Moottorin merkki ja malli pakollinem":"Motorns märke och modell krävs"})); error=true}
    if(!form.engine_cycle||form.engine_cycle==="") {setFormError(prevState => ({...prevState, engine_cycle: lang==='fi'?"Moottorin tahtimäärä pakollinen":"Motorns taktmängd krävs"})); error=true}
    if(!form.engine_hp||form.engine_hp===""){setFormError(prevState=> ({...prevState, engine_hp: lang==='fi'?"Moottorin teho pakollinen":"Motorns effekt krävs"})); error=true}
    if(!form.winterstorage_type){setFormError(prevState=> ({...prevState, winterstorage_type: lang==='fi'?"Säilytyksen tyyppi pakollinen":"Förvaringens typ krävs"})); error=true}
    return error;
  }

  const SendForm = async (e) => {
    e.preventDefault()
    if ((CheckFormNotNulls()||CheckFormErrors())||(CheckFormNotNulls()&&CheckFormErrors())) {
      setErrorMessage(fromDB.language_settings.form_errorcheck_fail.text)
      return
    } else {
      let form_send = form
      form_send.lang=lang
      /* DATE AND TIME RMOVED */
      form_send.pickup_datetime= ''
      form_send.launch_datetime= ''
      if (form_send.engine_internal=== true) form_send.engine_internal= 'yes'
      if (form_send.engine_internal=== false) form_send.engine_internal= 'no'
      const postForm = await fetch('https://app.venekeskus.com/api/customer/form?token='+ fromDB.token, {
          credentials: 'include',
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
          },
          method: 'POST',
          body: JSON.stringify(form_send)
        }
      )
      const data = await postForm.json();
      if (postForm.status===200) {
        setFormSuccess(true)
      } else {
        setErrorMessage(fromDB.language_settings.form_submit_fail.text)
      }
    }
  }

  if (loading.current=== false) { return (
    <div className="Form-Container" onClick={()=>{setErrorMessage("")}}>
      <div className="Header-Container">
        {/*<h1>{lang==='fi'?"TALVISÄILYTYSLOMAKE":"VINTERFÖRVARINGSBLANKETT"}</h1>*/}
      </div>
      <div className="Form">
        <h1>{fromDB.language_settings.main_header.text}</h1>
        <h4>{fromDB.language_settings.second_header.text}</h4>
        <a href='https://www.venekeskus.com/sv/talvisailytys#sailytyshinnasto' target="_self" className="LinkButton">{fromDB.language_settings.link_prices.text}</a>
        <br/><br/>
        <form onSubmit={(e)=>{if (!formSuccess) {SendForm(e)} else {e.preventDefault(); setErrorMessage(fromDB.language_settings.form_submit_twice.text)}}}>
          <h2>{fromDB.language_settings.customer_info.text}</h2>
          {["name", "phone", "email"].map((e, i) => { return(
            <FormElement key={i} setFormError={setFormError} CheckFormErrors={CheckFormErrors} form={form} name={e}
              setForm={setForm} formError={formError} onChange={e==="name"?OnChangeName:e==="phone"?OnChangePhone:OnChange} fromDB={fromDB.language_settings[e]}/>
          )})}
          {fromDB.language_settings.already_customer.active===1&&
            <div className="Form-Element-CheckBox">
              <label htmlFor="already_customer">{fromDB.language_settings.already_customer.text}</label>
              <div>
                <input 
                  name="already_customer" 
                  id="already_customer" 
                  type="checkbox" 
                  checked={form?.already_customer===true?true:false} 
                  onChange={(e)=> {setForm(prevState => ({...prevState, [e.target.name]: e.target.checked===false?false:true}))}}/>
              </div>
            </div>}
          <h2>{fromDB.language_settings.boat_info.text}</h2>
          {["boat", "boat_length", "boat_width", "boat_register"].map((e, i) => { 
            if (fromDB.language_settings[e].active) { return (
              <FormElement key={i} setFormError={setFormError} CheckFormErrors={CheckFormErrors} form={form} name={e}
                setForm={setForm} formError={formError} onChange={e==="boat_length"||e==="boat_width"?OnChangeMeters:OnChange} fromDB={fromDB.language_settings[e]}/>  
          )} else return null})}
          <h2>{fromDB.language_settings.engine_info.text}</h2>
          {["engine", "engine_hp", "engine_amount"].map((element, i) => { 
            if (fromDB.language_settings[element].active) { return (
              <FormElement key={i} setFormError={setFormError} CheckFormErrors={CheckFormErrors} form={form}
                setForm={setForm} formError={formError} onChange={OnChange} name={element} fromDB={fromDB.language_settings[element]}/>  
          )} else return null})}
          <div className="Form-Element-Radio">
            <label>{fromDB.language_settings.engine_cycle.text}</label>
            <div>
              {formError?.engine_cycle?<div className="Error"><p>{formError.engine_cycle}</p></div>:null}
              <input 
                type="radio" 
                name="engine_cycle" 
                value="2-stroke" 
                id="2-stroke" 
                checked={form?.engine_cycle==="2-stroke"} 
                onClick={()=>setFormError(prevState=> ({...prevState, engine_cycle: false}))}
                onChange={OnChange}/>
              <label htmlFor="engine_cycle" onClick={()=> {
                setForm(prevState=> ({...prevState, engine_cycle:"2-stroke"}));
                setFormError(prevState=> ({...prevState, engine_cycle: false}))}}>{fromDB.language_settings["2_stroke"].text}</label>
            </div>
            <div>
              <input 
                type="radio" 
                name="engine_cycle" 
                value="4-stroke" 
                id="4-stroke" 
                checked={form?.engine_cycle==="4-stroke"} 
                onClick={()=>setFormError(prevState=> ({...prevState, engine_cycle: false}))}
                onChange={OnChange}/>
              <label htmlFor="engine_cycle" onClick={()=> {
                setForm(prevState=> ({...prevState, engine_cycle:"4-stroke"}));
                setFormError(prevState=> ({...prevState, engine_cycle: false}))}}>{fromDB.language_settings["4_stroke"].text}</label>
            </div>
          </div>
          <div className="Form-Element-CheckBox">
            <label htmlFor="engine_internal">{fromDB.language_settings.engine_internal.text}</label>
            <div>
              <input 
                name="engine_internal" 
                id="engine_internal" 
                type="checkbox" 
                checked={form?.engine_internal===true?true:false} 
                onChange={(e)=> {setForm(prevState => ({...prevState, [e.target.name]: e.target.checked===false?false:true}))}}/>
            </div>
          </div>
          <h2>{fromDB.language_settings.winterstorage_info.text}</h2>
          <div className="Form-Element-Radio">
            <label>{fromDB.language_settings.winterstorage_type.text}</label>
            <div>
              {formError?.winterstorage_type?<div className="Error"><p>{formError.winterstorage_type}</p></div>:null}
              <input 
                type="radio" 
                name="winterstorage_type" 
                value="warm" 
                id="warm" 
                onClick={()=>setFormError(prevState=> ({...prevState, winterstorage_type: false}))}
                checked={form?.winterstorage_type==="warm"} 
                onChange={OnChange}/>
              <label htmlFor="winterstorage_type" onClick={()=> {
                setForm(prevState=> ({...prevState, winterstorage_type:"warm"}))
                setFormError(prevState=> ({...prevState, winterstorage_type: false}))}}>{fromDB.language_settings.warm.text}</label>
            </div>
            <div>
              <input 
                type="radio" 
                name="winterstorage_type" 
                value="cold" 
                id="cold" 
                onClick={()=>setFormError(prevState=> ({...prevState, winterstorage_type: false}))}
                checked={form?.winterstorage_type==="cold"} 
                onChange={OnChange}/>
              <label htmlFor="winterstorage_type" onClick={()=> {
                setForm(prevState=> ({...prevState, winterstorage_type:"cold"}))
                setFormError(prevState=> ({...prevState, winterstorage_type: false}))}}>{fromDB.language_settings.cold.text}</label>
            </div>
          </div>
          <div className="Animated-Div" style={{
              transform: form?.winterstorage_type?"scaleY(1)":"scaleY(0)",
              maxHeight: form?.winterstorage_type?500:0}}>
            <Calendar 
              setState={setForm} 
              lang={lang} 
              fromDB={fromDB.language_settings} 
              fromDBnow={fromDB.now} 
              availableTimes={fromDB.calendar_time_settings}
              availableDates={fromDB.calendar_date_settings}/>
            {fromDB.language_settings.calendar_addon.text}
          </div>
          <h2>{fromDB.language_settings.services.text}</h2>
          <div className="Form-Element-Radio">
            <label>{fromDB.language_settings.pickup_and_launch.text}</label>
            <div>
              <input 
                type="radio" 
                name="pickup_and_launch" 
                value="yes" 
                id="yes"
                checked={form?.pickup_and_launch==="yes"} 
                onChange={()=> {
                  if (form.pickup_and_launch===undefined) { 
                    setForm(prevState => ({...prevState, pickup_and_launch: "yes", pickup_location: fromDB.pickup_locations[0].value}))
                  } else setForm(prevState => ({...prevState, pickup_and_launch: "yes"}))
                }}/>
              <label htmlFor="pickup_and_launch" onClick={()=> {
                if (form.pickup_and_launch===undefined) {
                    setForm(prevState => ({...prevState, pickup_and_launch: "yes", pickup_location: fromDB.pickup_locations[0].value}))
                  } else setForm(prevState => ({...prevState, pickup_and_launch: "yes"}))
              }}>{lang==='fi'?"Kyllä":"Ja"}</label>
            </div>
            <div>
              <input 
                type="radio" 
                name="pickup_and_launch" 
                value="no" 
                id="no"
                checked={form?.pickup_and_launch==="no"} 
                onChange={()=>setForm(prevState => ({...prevState, pickup_and_launch:"no", pickup_other: undefined}))}/>
              <label htmlFor="pickup_and_launch" onClick={()=>setForm(prevState => ({...prevState, pickup_and_launch:"no", pickup_other: undefined}))}>{lang==='fi'?"Ei":"Nej"}</label>
            </div>
          </div>
          <div className="Animated-Div" style={{
              transform: form?.pickup_and_launch==="yes"?"scaleY(1)":"scaleY(0)",
              maxHeight: form?.pickup_and_launch==="yes"?500:0}}>
            <div className="Form-Element-Select">
              <label htmlFor="pickup_location">{fromDB.language_settings.pickup_location.text}</label>
              <div>
                <select id="pickup_location" name="pickup_location" onChange={e=>{OnChange(e); setForm(prevState => ({...prevState, pickup_other: undefined}))}}>
                  {fromDB?.pickup_locations?.map((pickup_location, i) => { 
                    return (<option value={pickup_location.value} key={i}>{lang==='fi'?pickup_location.fi:pickup_location.sv}</option>)
                  })}
                </select>
              </div>
            </div>
            <div className="Animated-Div" style={{
                transform: form?.pickup_location==="other"?"scaleY(1)":"scaleY(0)",
                maxHeight: form?.pickup_location==="other"?500:0}}>
              <FormElement setFormError={setFormError} CheckFormErrors={CheckFormErrors} form={form}
                setForm={setForm} formError={formError} onChange={OnChange} name="pickup_other" fromDB={fromDB.language_settings.pickup_other}/>
            </div>
          </div>
            {fromDB?.service_radiobuttons?.map((service_radiobutton, i) => {
              return (
                <div className="Form-Element-Radio" key={i}>              
                  <label>{service_radiobutton[lang]}</label>
                  <div>
                    <input
                      type="radio"
                      name={service_radiobutton.name}
                      value="yes"
                      id="yes"
                      checked={form?.[service_radiobutton.name]==="yes"}
                      onChange={OnChange}/>
                    <label htmlFor={service_radiobutton.name} onClick={()=> setForm(prevState => ({...prevState, [service_radiobutton.name]: "yes"}))}>{lang==='fi'?"Kyllä":"Ja"}</label>
                  </div>
                  <div>
                    <input 
                      type="radio" 
                      name={service_radiobutton.name}
                      value="no" 
                      id="no" 
                      checked={form?.[service_radiobutton.name]==="no"}
                      onChange={OnChange}/>
                    <label htmlFor={service_radiobutton.name} onClick={()=> setForm(prevState => ({...prevState, [service_radiobutton.name]: "no"}))}>{lang==='fi'?"Ei":"Nej"}</label>
                  </div>
                </div>                
              )
            })}
          {["boat_other_services"].map((element, i) => { return (
            <FormElement key={i} setFormError={setFormError} CheckFormErrors={CheckFormErrors} form={form}
              setForm={setForm} formError={formError} onChange={OnChange} name={element} fromDB={fromDB.language_settings[element]}/>
          )})}
          {["discount"].map((element, i) => { return (
            <div className="Form-Element">
              <label htmlFor={element}>{fromDB?.language_settings[element].text} 
                <div className="InfoSquare" onClick={()=> setToggle(!toggle)}>i</div>
                {toggle&&
                <div className="InfoSquare-TextCont">
                  <div className="InfoSquare-Text">
                    {fromDB?.language_settings.info_button.text}
                  </div>
                </div>}
              </label>
              {formError?.[element]?<div className="Error"><p>{formError[element]}</p></div>:null}
              <input 
                style={formError?.[element]?{backgroundColor: "#ffefef"}:{}}
                onFocus={()=>{if (formError[element]) setFormError(prevState => ({...prevState, [element]: ""}))}}
                onBlur={()=>{
                  if (form[element]&&(typeof form[element]==='string')) 
                    setForm(prevState=> ({...prevState, [element]: form[element].trim()}))
                  CheckFormErrors()}}
                type="text" 
                value={form?.[element]?form[element]:''} 
                name={element}
                onChange={(e)=>OnChange(e)}/>
            </div>
          )})}
          {errorMessage&&
            <div className="Form-Element-Radio">
              <label></label>
              <div>
                <PopUpError/>
              </div>
              <div></div>
            </div>}
          {formSuccess&&
            <div className="Form-Element-Radio">
            <label></label>
            <div>
              <h3>{fromDB.language_settings.form_submit_success.text}</h3>
            </div>
            <div></div>
            </div>}
          <div className="Form-Element-Radio">
            <label></label>
            <div className="Button">
              <input 
                type="submit" 
                name="submit" 
                value={fromDB.language_settings.submit.text}/>
            </div>
            <div></div>
          </div>
        </form>
      </div>
    </div>
  ) } else return (
    <div><h3>{fetchErrorMessage}</h3></div>
  )
}

export default WinterStorageForm;
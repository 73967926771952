import React from 'react';

function FormElement(props) {
  //console.log(props.fromDB.[props.name]?.active)
  //console.log(props.fromDB)
  return (
    <div className="Form-Element">
    <label htmlFor={props.name}>{props.fromDB?.text}</label>
    {props.formError?.[props.name]?<div className="Error"><p>{props.formError[props.name]}</p></div>:null}
    <input 
      placeholder={props.placeholder}
      style={props.formError?.[props.name]?{backgroundColor: "#ffefef"}:{}}
      onFocus={()=>{if (props.formError[props.name]) props.setFormError(prevState => ({...prevState, [props.name]: ""}))}}
      onBlur={()=>{
        if (props?.form[props?.name]&&(typeof props?.form[props?.name]==='string')) 
          props.setForm(prevState=> ({...prevState, [props.name]: props.form[props.name].trim()}))
        props.CheckFormErrors()}}
      type="text" 
      value={props.form?.[props.name]?props.form[props.name]:''} 
      name={props.name}
      onChange={(e)=>props.onChange(e)}/>
  </div>
  )
}

export default FormElement;
import React, {useEffect, useState} from 'react'
import './Calendar.css'

function Calendar({setState, lang, fromDB, fromDBnow, availableTimes, availableDates}) {
  const string1= fromDB.calendar_pickup_date.text
  const string2= fromDB.calendar_launch_date.text
  const DAYSOFWEEK= lang==='fi'?[
    "Ma", "Ti", "Ke", "To", "Pe", "La", "Su"
  ]:[
    "Mån", "Tis", "Ons", "Tors", "Fre", "Lör", "Sön"
  ]
  const MONTHSOFYEAR= lang==='fi'?[
    "Tammikuu", "Helmikuu", "Maaliskuu", "Huhtikuu", "Toukokuu", "Kesäkuu", "Heinäkuu", "Elokuu", "Syyskuu", "Lokakuu", "Marraskuu", "Joulukuu"
  ]:[
    "Januari", "Februari", "Mars", "April", "Maj", "Juni", "Juli", "Augusti", "September", "Oktober", "November", "December"
  ]
  const TIMESAVAILABLE  = availableTimes
  const now             = new Date(fromDBnow)
  // Safari IE => new Date()
  let pickuprangefrom   = availableDates.date_pickup_range.split('-')[0].split('.')[0]+'/'+ availableDates.date_pickup_range.split('-')[0].split('.')[1]
  let pickuprangeto     = availableDates.date_pickup_range.split('-')[1].split('.')[0]+'/'+ availableDates.date_pickup_range.split('-')[1].split('.')[1]
  let launchrangefrom   = availableDates.date_launch_range.split('-')[0].split('.')[0]+'/'+ availableDates.date_launch_range.split('-')[0].split('.')[1]
  let launchrangeto     = availableDates.date_launch_range.split('-')[1].split('.')[0]+'/'+ availableDates.date_launch_range.split('-')[1].split('.')[1]

  /*if (new Date(pickuprangefrom+"/"+now.getFullYear()).getTime()< Date.now()+(86400000 * 7)) { //86400000 * 7 = 7 days
    console.log(Date.now())
  }*/
  new Date((availableDates.date_pickup_range.split('-')[0].split('.')[0]-1)+'/'+ availableDates.date_pickup_range.split('-')[0].split('.')[1]+"/"+now.getFullYear())
  let pickupDateFrom    = new Date(pickuprangefrom+"/"+now.getFullYear())
  const pickupDateTo    = new Date(pickuprangeto+"/"+ now.getFullYear())
  const launchDateFrom  = new Date(launchrangefrom+"/"+ (now.getFullYear()+1))
  const launchDateTo    = new Date(launchrangeto+"/"+ (now.getFullYear()+1))
  const allowDaysFrom   = availableDates.allow_weekdays.split('-')[0]
  const allowDaysTo     = availableDates.allow_weekdays.split('-')[1]


  // If allowed pickup"from" date is less than 5 days ahead, then add
  while (pickupDateFrom.getTime()< (now.getTime()+(86400000 * 5))) {
    pickupDateFrom = new Date(new Date(pickupDateFrom).getTime()+(86400000 * 7))
  }

  const [dayPickup, setDayPickup]     = useState(pickupDateFrom.getDate())
  const [dayLaunch, setDayLaunch]     = useState(launchDateFrom.getDate())
  const [monthPickup, setMonthPickup] = useState(pickupDateFrom.getMonth())
  const [monthLaunch, setMonthLaunch] = useState(launchDateFrom.getMonth())
  const [yearPickup, setYearPickup]   = useState(pickupDateFrom.getFullYear())
  const [yearLaunch, setYearLaunch]   = useState(launchDateFrom.getFullYear())
  const [hoursPickup, setHoursPickup] = useState(availableTimes[0])
  const [hoursLaunch, setHoursLaunch] = useState(availableTimes[0])

  useEffect(()=> { // triggers every date update
    setState(prevState => ({
      ...prevState, 
      pickup_datetime: dayPickup+ "."+ (monthPickup+ 1)+ "."+ yearPickup+ " "+ hoursPickup, // month 0..11 => 1..12
      launch_datetime: dayLaunch+ "."+ (monthLaunch+ 1)+ "."+ yearLaunch+ " "+ hoursLaunch
    }))
  }, [dayPickup, dayLaunch, monthPickup, monthLaunch, yearPickup, yearLaunch, hoursPickup, hoursLaunch])

  function allowedDate(target, newDate) { // return wether newDate is allowed
    if (((newDate.getDay()|| 7)< allowDaysFrom)|| ((newDate.getDay()|| 7)> allowDaysTo)) return false
    if (target==="launch") {
      if ((newDate>= launchDateFrom)&&(newDate< launchDateTo)) {return true} else {return false}
    } else {
      if ((newDate>= pickupDateFrom)&&(newDate< pickupDateTo)) {return true} else {return false}
    }
  }
  
  function addMonth(target) {
    addWeek(target, 4)
  }

  function subMonth(target) {
    subWeek(target, 4)
  }

  function addWeek(target, times) {
    if (times===undefined) times= 0
    let d;
    if (target=== "launch") {
      let daysLeftOfWeek= 8- (new Date(yearLaunch, monthLaunch, dayLaunch).getDay()|| 7)
      for (let i= daysLeftOfWeek; i< daysLeftOfWeek+ 7; i++) {
        d= new Date(new Date(yearLaunch, monthLaunch, dayLaunch).getTime()+ 864* 100000* (i+ (times* 7)))
        if (allowedDate('launch', d)) {
          setYearLaunch(d.getFullYear())
          setMonthLaunch(d.getMonth())
          setDayLaunch(d.getDate())   
          return
        }
      }
    } else {
      let daysLeftOfWeek= 8- (new Date(yearPickup, monthPickup, dayPickup).getDay()|| 7)
      for (let i= daysLeftOfWeek; i< daysLeftOfWeek+ 7; i++) {
        d= new Date(new Date(yearPickup, monthPickup, dayPickup).getTime()+ 864* 100000* (i+ (times* 7)))
        if (allowedDate('pickup', d)) {
          setYearPickup(d.getFullYear())
          setMonthPickup(d.getMonth())
          setDayPickup(d.getDate())
          return
        }
      }
    }
  }

  function subWeek(target, times) {
    if (times===undefined) times= 0
    let d;
    if (target==="launch") {
      let daysLeftOfWeek= new Date(new Date(yearLaunch, monthLaunch, dayLaunch).getTime()+ 864* 100000* 7).getDay()|| 7
      for (let i= daysLeftOfWeek; i< daysLeftOfWeek+ 7; i++) {
        d= new Date(new Date(yearLaunch, monthLaunch, dayLaunch).getTime()- 864* 100000* (i+ (times* 7)))
        if (allowedDate('launch', d)) {
          setYearLaunch(d.getFullYear())
          setMonthLaunch(d.getMonth())
          setDayLaunch(d.getDate())   
          return
        }
      }
    } else {
      let daysLeftOfWeek= new Date(new Date(yearPickup, monthPickup, dayPickup).getTime()+ 864* 100000* 7).getDay()|| 7
      for (let i= daysLeftOfWeek; i< daysLeftOfWeek+ 7; i++) {
        d= new Date(new Date(yearPickup, monthPickup, dayPickup).getTime()- 864* 100000* (i+ (times* 7)))
        if (allowedDate('pickup', d)) {
          setYearPickup(d.getFullYear())
          setMonthPickup(d.getMonth())
          setDayPickup(d.getDate())
          return
        }
      }
    }
  }

  return (
    <>
    </>
  )
}
{/*}
    <div className="Form-Calendar-Container">
      <div className="Form-Calendar">
        <div className="Form-Calendar-Header-Container">
          <div className="Form-Calendar-Header">
            {string1.split('/n').map(text => (text+'\n') )}
          </div>
        </div>
        <div className="Form-Calendar-Month-Container">
          <div className="Form-Calendar-Date-Arrow" onClick={()=> subMonth()}>
            <div className="doublearrow-container"> 
              <div className="doublearrow doubleleft"/>
            </div>
          </div>
          <div className="Form-Calendar-Date-Arrow" onClick={()=> subWeek()}>
            <div className="arrow-container">
              <div className="arrow left"/>
            </div>
          </div>
          <div className="Form-Calendar-Month">
            {MONTHSOFYEAR[monthPickup]+ " "+ yearPickup}
          </div>
          <div className="Form-Calendar-Date-Arrow" onClick={()=> addWeek()}>
            <div className="arrow-container">
              <div className="arrow right"/>
            </div>
          </div>
          <div className="Form-Calendar-Date-Arrow" onClick={()=> addMonth()}>
            <div className="doublearrow-container">
              <div className="doublearrow doubleright"/>
            </div>
          </div>
        </div>
        <div className="Form-Calendar-Date-Container">
          <div className="Form-Calendar-Date-From">
            {DAYSOFWEEK.map((day, i) => {
              let d= new Date(yearPickup, monthPickup, dayPickup)
              i= i+ 1
              //if (!((i>= allowDaysFrom)&&(i<= allowDaysTo))) return; // removes not allowed weekdays
              let thisDay= d.getDay()|| 7;
              if (thisDay=== 7) d= new Date(d.getTime()- (864* 100000))
              if (i=== thisDay) { return (
                <div key={i} className="Form-Calendar-Date-Selected">
                  {day+ " "+ (thisDay===7?(new Date(d.getTime()+ (864* 100000)).getDate()):(d.getDate())) + "."+ 
                  (thisDay===7?((new Date(d.getTime()+ (864* 100000))).getMonth()+ 1):(d.getMonth()+ 1))}
                </div>
              )} else {
                let d2
                d2= new Date(d.getTime()+ (864* 100000* (i- d.getDay()))) // before i
                return (
                  <div key={i} className="Form-Calendar-Date" onClick={()=> {
                    if (allowedDate("pickup", d2)) {
                      setDayPickup(d2.getDate())
                      setMonthPickup(d2.getMonth())
                    }
                  }}>
                    {d2.getDate()+ "."+ (d2.getMonth()+ 1)}
                  </div>
                )
              }
            })}
          </div>
        </div>
        <div className="Form-Calendar-Time-Container">
          {/*TIMESAVAILABLE.map((time, i) => {
            if (time==hoursPickup) {return (
              <div key={i} className="Form-Calendar-Time-Selected">
                {time}
              </div>
            ) } else return (
              <div key={i} className="Form-Calendar-Time" onClick={()=> {setHoursPickup(time)}}>
                {time}
              </div>
            )
          })
        </div>
      </div>
      <div className="Form-Calendar">
        <div className="Form-Calendar-Header-Container">
          <div className="Form-Calendar-Header">
            {string2.split('/n').map(text => (text+'\n') )}
          </div>
        </div>
        <div className="Form-Calendar-Month-Container">
          <div className="Form-Calendar-Date-Arrow" onClick={()=> subMonth("launch")}>
          <div className="doublearrow-container"> 
              <div className="doublearrow doubleleft"/>
            </div>
          </div>
          <div className="Form-Calendar-Date-Arrow" onClick={()=>{subWeek("launch")}}>
            <div className="arrow-container">
              <div className="arrow left"/>
            </div>
          </div>
          <div className="Form-Calendar-Month">
            {MONTHSOFYEAR[monthLaunch]+ " "+ yearLaunch}
          </div>
          <div className="Form-Calendar-Date-Arrow" onClick={()=>{addWeek("launch")}}>
            <div className="arrow-container">
              <div className="arrow right"/>
            </div>
          </div>
          <div className="Form-Calendar-Date-Arrow" onClick={()=> addMonth("launch")}>
          <div className="doublearrow-container">
              <div className="doublearrow doubleright"/>
            </div>
          </div>
        </div>
        <div className="Form-Calendar-Date-Container">
          <div className="Form-Calendar-Date-From">
            {DAYSOFWEEK.map((day, i) => {
              let d= new Date(yearLaunch, monthLaunch, dayLaunch)
              i= i+ 1
              let thisDay= d.getDay()|| 7;
              if (thisDay=== 7) d= new Date(d.getTime()- (864* 100000* 1))
              if (i=== thisDay) { return (
                <div key={i} className="Form-Calendar-Date-Selected">
                  {day+ " "+ (thisDay===7?(new Date(d.getTime()+ (864* 100000* 1)).getDate()):(d.getDate())) + "."+ 
                  (thisDay===7?((new Date(d.getTime()+ (864* 100000* 1))).getMonth()+ 1):(d.getMonth()+ 1))}
                </div>
              )} else {
                let d2;
                d2= new Date(d.getTime()+ (864* 100000* (i- d.getDay()))) // after i
                return (
                  <div key={i} className="Form-Calendar-Date" onClick={()=> {
                    if (allowedDate("launch", d2)) {
                      setDayLaunch(d2.getDate());
                      setMonthLaunch(d2.getMonth());
                    }
                  }}>
                    {d2.getDate()+ "."+ (d2.getMonth()+1)}
                  </div>
                )
              }
            })}
          </div>
        </div>
      <div className="Form-Calendar-Time-Container">
        {/*TIMESAVAILABLE.map((time, i) => {
          if (time==hoursLaunch) {return (
            <div key={i} className="Form-Calendar-Time-Selected">
              {time}
            </div>
          ) } else return (
            <div key={i} className="Form-Calendar-Time" onClick={()=> {setHoursLaunch(time)}}>
              {time}
            </div>
          )})
        </div>
      </div>
    </div>
        )*/
}

export default Calendar;